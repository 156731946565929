import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConnectPolicyPage, LandingPage } from './pages';

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "/home" },
  { path: "home", component: LandingPage },
  { path: "connect", component: ConnectPolicyPage },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [ ]
})
export class AppRoutingModule { }
