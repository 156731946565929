import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public status$: BehaviorSubject<any> = new BehaviorSubject<any>({ active: false, message: "Loading" });

  public show(message: string): void {
    this.status$.next({ active: true, partial: false, message: message });
  }

  // SHow a smaller loader panel without obscuring the whole view
  public partial(message: string): void {
    this.status$.next({ active: true, partial: true, message: message });

  }

  public hide() : void {
    this.status$.next({ active: false });
  }
}
