<app-loading-container *ngIf="loading" [loading]="loading"></app-loading-container>
<mat-toolbar color="accent" class="nav-toolbar">

  <mat-toolbar-row class="main-toolbar">
    <button class="hamburger" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Main menu">
      <mat-icon>menu</mat-icon>
    </button>
    <a [routerLink]="['']" class="desktop-only" id="imgHeaderBrand"><img src="assets/logo/base-256.png"></a>
    <a [routerLink]="['']" class="mobile-only" id="imgHeaderBrandMobile"><img src="assets/logo/base-256.png"></a>
    <img class="cog-overlay" src="assets/logo/overlay-256.png">
    <div class="brand-overlay">GDPR &amp; Privacy</div>
    <div class="nav-title desktop-only cbs-blue">{{location}}</div>
    <span class="nav-spacer"></span>

    <div class="nav-separator desktop-only"> </div>

  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="contact()">
    <mat-icon>contacts</mat-icon>
    <span>Contact us</span>
  </button>
  <button mat-menu-item (click)="about()">
    <mat-icon>info</mat-icon>
    <span>About us</span>
  </button>
  <button mat-menu-item (click)="privacy()">
    <mat-icon>description</mat-icon>
    <span>Privacy policy</span>
  </button>
</mat-menu>


