import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { MaterialModule, AppCoreModule } from './app-core';
import { LandingPage } from './pages';
import { LoaderService } from './services/loader.service';

@NgModule({
  declarations: [
    AppComponent,
    LandingPage,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppCoreModule,
  ],
  providers: [LoaderService],
  bootstrap: [AppComponent],
  entryComponents: [
  ]
})
export class AppModule { }
