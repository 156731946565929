import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingPage {

  public visitWebsite(): void {
    window.open('https://collaborative.fm', `collab`);
  }

}
