import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  public loading: boolean = false;
  public location: string = 'Insight';

  constructor(private router: Router) {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.loading = false;
        this.location = this._getLocation(e.url);
      }
    });
  }

  public _getLocation(url: string): string {
    if (!url) { return "Insight"; }
    if (url.startsWith("/connect")) { return "Connect"; }
    return "Insight";
  }


  public contact(): void {
    window.open("https://collaborative.fm/contact-us/", "_blank");
  }

  public privacy(): void {
    window.open("https://collaborative.fm/privacy-policy/", "_blank");
  }

  public about(): void {
    window.open("https://collaborative.fm/product/", "_blank");
  }
}
