<div class="page-content-container">
  <div class="headline">Connect Mobile Privacy Policy.</div>

  <p>
    We are committed to ensuring we handle your data transparently, securely, responsibly and in accordance with regulations.
  </p>
  <p>
    In this document, we’ll explain what we collect and why; how and where your data might be shared what your rights are.
  </p>
  <br/>
  <hr/>

  <div class="section-title">What types of data do we store?</div>
  <h4>Identity Information</h4>
  <p>
    Details which enable us to uniquely identify you, primarily when accessing Connect Mobile or our web portal area to manage quotations, orders and jobs. This will usually be your email address and password.
  </p>

  <h4>Contact information</h4>
  <p>Means for us to get in touch. Usually your email address or telephone number.</p>

  <h4>Location services</h4>
  <p>Connect Mobile will ask to use location information when first installed. This is requested in order to help establish your estimated time of arrival when travelling to a job, and to dispatch new jobs to you which are appropriate to your location. Your location is only collected while you are logged in to the mobile application and is not shared with advertisers.</p>

  <h4>Transaction data</h4>
  <p>We retain details of any quotations or orders processed through the system for a period of at least 12 months after completion.</p>
  <p>This is to ensure you have accurate historical information as well as providing the ability for us to perform trend analysis and performance monitoring over time. These elements are necessary for our "Quote Insight" and other machine learning based features.</p>

  <h4>Application performance metrics</h4>
  <p>Device performance information, diagnostics and crash data are recorded to help identify issues and improve the application going forward.</p>

  <h4>Aggregated data</h4>
  <p>Information that isn't specific to users to help us understand numbers of orders placed, average site sizes etc.</p>

  <div class="section-title">Use of data</div>
  <p>We'll only request and use data about you for legitimate purposes aligned to the use cases listed above. We won't ask for more information than we need to provide a given function.</p>


  <div class="section-title">Sharing your data</div>
  <p>We'll fully disclose and ask your permission before providing any information to our partners and associated services.</p>

  <div class="section-title">Protection of your data</div>
  <p>Your data will be secured in transit, stored securely and procedurally safeguarded. SSL and other encryptions are used to protect your data both in transfer and when stored, combined with firewalls, security patching and other mechanisms to protect against intrusion to our internal systems.</p>

  <div class="section-title">Our obligations</div>
  <p>Any requests for information we hold about you will be honoured as promptly as practical. Please submit any requests to the Data Compliance Officer (address as listed below).</p>

  <div class="section-title">Cookies and tracking information</div>
  <p>Cookies are small text files containing a unique identifier, which are stored on your computer or mobile device so that your device can be recognised when you are using a particular website or mobile app. They can be used only for the duration of your visit or they can be used to measure how you interact with services and content over time. Cookies help to provide important features and functionality on our software platform. Cookies can also be used help us prevent security breaches and so we may record information about your device within the cookie.  </p>
</div>

