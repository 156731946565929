import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connect-policy-page',
  templateUrl: './connect-policy.component.html',
  styleUrls: ['./connect-policy.component.scss']
})
export class ConnectPolicyPage {

  public visitWebsite(): void {
    window.open('https://collaborative.fm', `collab`);
  }

}
