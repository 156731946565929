import { Component, OnDestroy, OnInit } from '@angular/core';
import { version } from '../../package.json';
import { NavigationEnd, Router } from '@angular/router';
import { LoaderService } from './services/loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Collaborative.fm Portal';
  public version: string = version;
  public displayVersion: string;
  public loaderStatus: any = { active: false }
  private loaderSubscription: Subscription;

  constructor(private loaderService: LoaderService, private router: Router) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function(){
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
          this.router.navigated = false;
          window.scrollTo(0, 0);
      }
    });

    this.loaderSubscription = this.loaderService.status$.subscribe((val) => {
      setTimeout(() => this.loaderStatus = val, 50);
    });
  }

  ngOnDestroy(): void {
    this.loaderSubscription?.unsubscribe();
  }

  public goback(): void {
    window.history.back();
  }

}
